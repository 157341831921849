import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ConfirmationModal from "../../sub/modals/ConfirmationModal";
import DeskInitialPage from "./pages/DeskInitialPage";
import DeskOngoingPage from "./pages/DeskOngoingPage";
import EmailModal from "../../sub/modals/EmailModal";
import DeskTemplatePDF from "./DeskTemplatePDF";
import DeskTemplateCerfaPDF from "./DeskTemplateCerfaPDF";

import PDFUtil from "../../../util/PDFUtil";
import {
  addDeskAudit,
  sendDeskAudit,
} from "../../../actions/deskaudit/deskaudit";
import Util from "../../../util/Util";
import { Container, Modal, Row } from "react-bootstrap";
import DeskTemplateEstimatePDF from "./DeskTemplateEstimatePDF";
import { injectIntl } from "react-intl";
import { getDeskCategories } from "../../../actions/settings/deskCategories/admin/deskCategories";
import { getDeskProducts } from "../../../actions/settings/deskCategories/admin/deskProducts";

class AddPatientDeskModal extends React.Component {
  constructor(props) {
    super(props);

    if (Util.emptyObject(this.props.savedAudit)) {
      const audit = {
        appVersion: process.env.REACT_APP_VERSION,
        patient_id: this.props.patient._id,
        prescriber_id: "",
        helper_id: "",
        audit_id: "",
        category_id: null,
        product_id: null,
        initialCategoryId: null,
        mandatoryCategoryId: null,
        categoriesVisited: [],
        deskStore: {},
        deskCart: [],
        template: "",
        templateCerfa: "",
        templateEstimate: "",
        isLoading: false,
        patient_analysis: {
          gender: "",
          height: 0,
          weight: 0,
          shoesize: 0,
          incontinence: "",
          bedsores: "",
          homecare_workers: "",
          home_accessibility: "",
        },
      };

      Util.addPatientAuditInLocalStorage(
        this.props.patient._id,
        audit,
        "saved_desk_audit",
      );

      this.state = {
        modal: null,
        disabled: false,

        // This modal is used for the whole Audit. 3 possible screens:
        // - initial: choose basic info about the audit (patient, helper, prescriber etc.)
        // - ongoing: do the audit (audit the rooms, the equipment etc.)
        // - signature: end audit (signature, emails etc.)
        // - email: choose whom to send the emails to
        mode: "initial", // "ongoing", "signature", "email"

        // Initial screen data
        prescriber_id: "",
        helper_id: "",
        audit_id: "",
        patient_analysis: {
          gender: "",
          height: 0,
          weight: 0,
          shoesize: 0,
          incontinence: "",
          bedsores: "",
          homecare_workers: "",
          home_accessibility: "",
        },
        // Ongoing screen data

        category_id: null,
        product_id: null,
        initialCategoryId: null,
        mandatoryCategoryId: null,
        categoriesVisited: [],

        deskStore: {},
        deskCart: [],
        // Signature screen data
        tech_signature: null,
        rep_signature: null,
        template: "",
        templateCerfa: "",
        templateEstimate: "",
        isLoading: false,
      };
    } else {
      this.state = {
        modal: null,
        disabled: false,

        // This modal is used for the whole Audit. 3 possible screens:
        // - initial: choose basic info about the audit (patient, helper, prescriber etc.)
        // - ongoing: do the audit (audit the rooms, the equipment etc.)
        // - signature: end audit (signature, emails etc.)
        // - email: choose whom to send the emails to
        mode: "initial", // "ongoing", "signature", "email"

        // Initial screen data
        prescriber_id: this.props.savedAudit.prescriber_id || "",
        helper_id: this.props.savedAudit.helper_id || "",
        audit_id: this.props.savedAudit.audit_id || "",
        patient_analysis: this.props.savedAudit.patient_analysis || {},
        // Ongoing screen data

        category_id: this.props.savedAudit.category_id || null,
        product_id:
          Object.keys(this.props.savedAudit.deskStore).length > 0
            ? Object.keys(this.props.savedAudit.deskStore)[0]
            : null,
        initialCategoryId: this.props.savedAudit.initialCategoryId || null,
        mandatoryCategoryId: this.props.savedAudit.mandatoryCategoryId || null,
        categoriesVisited: this.props.savedAudit.categoriesVisited || [],

        deskStore: this.props.savedAudit.deskStore || null,
        deskCart: this.props.savedAudit.deskCart || [],
        template: this.props.savedAudit.template || null,
        templateCerfa: this.props.savedAudit.templateCerfa || null,
        templateEstimate: this.props.savedAudit.templateEstimate || null,
        isLoading: false,
        // Signature screen data
        tech_signature: null,
        rep_signature: null,
      };
    }
  }

  componentDidMount() {
    this.props.onGetDeskCategories();
    this.props.onGetDeskProducts();
  }

  onDeskStore(product, state, callback) {
    let data = { deskStore: { ...this.state.deskStore, [product._id]: state } };

    if (Object.keys(this.state.deskStore).length === 0) {
      data.mandatoryCategoryId = product.category_id;
    }

    this.setState(data, callback);
  }

  delDeskStore(productId, callback) {
    var deskStore = this.state.deskStore;
    delete deskStore[productId];

    this.setState({ deskStore: deskStore }, callback);
  }

  addToCart(product, callback) {
    this.setState({ deskCart: [...this.state.deskCart, product] }, callback);
  }

  deleteFromCart(product, callback) {
    this.setState(
      { deskCart: this.state.deskCart.filter((p) => p._id !== product._id) },
      callback,
    );
  }

  updateCartProductQuantity(product, number) {
    const updatedCart = this.state.deskCart.map((p) => {
      if (p._id === product._id) return { ...p, quantity: p.quantity + number };
      return p;
    });

    this.setState({ deskCart: updatedCart });
  }

  close() {
    const confirm = () => this.props.close();

    this.setState({
      modal: (
        <ConfirmationModal
          variant="warning"
          title={<FormattedMessage id="Confirmation" />}
          onAccept={confirm}
          onDecline={() => this.closeModal()}
        >
          <FormattedMessage id="Really.Quit.Audit" />
        </ConfirmationModal>
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  addAudit() {
    var products = [];
    var initialProducts = [];
    var otherProducts = [];

    for (let _pid in this.state.deskStore) {
      let product = this.props.deskProducts.find((pr) => {
        return _pid === pr._id;
      });

      product.comment = this.state.deskStore[_pid].comment;

      if (
        product.category_id === this.state.mandatoryCategoryId ||
        product.category_id === this.state.initialCategoryId
      ) {
        initialProducts.push(product);
      } else {
        otherProducts.push(product);
      }
    }

    // REORDER PRODUCTS WITh INITIAL CATEGORY

    products = initialProducts.concat(otherProducts);

    const helper = this.props.helpers.find(
      (h) => h._id === this.state.helper_id,
    );
    const prescriber = this.props.prescribers.find(
      (p) => p._id === this.state.prescriber_id,
    );

    const data = {
      patient: {
        _id: this.props.patient._id,
        name: Util.formatFullName(
          this.props.patient.first_name,
          this.props.patient.name,
        ),
        address: this.props.patient.address,
        postal_code: this.props.patient.postal_code,
        city: this.props.patient.city,
        birth_date: this.props.patient.birth_date,
      },
      pharmacist: {
        _id: this.props.user._id,
        name: Util.formatFullName(
          this.props.user.first_name,
          this.props.user.name,
        ),
      },
      products: products.map((product) => ({
        _id: product._id,
        name: product.name,
        ref: product.ref,
        refundable: product.refundable,
        vte_loc: product.vte_loc,
        description_text: product.description_text,
        prescription_type_vte_text: product.prescription_type_vte_text,
        prescription_type_loc_text: product.prescription_type_loc_text,
        comment: product.comment,
      })),
      productsFromCatalog: this.state.deskCart.map((item) => ({
        _id: item._id,
        designation: item.designation,
        ref: item.ref,
        prix_u_ht_emera: item.prix_u_ht_emera,
        tva: item.tva,
        ref_frn: item.ref_frn,
        lpp_amount: item.lpp_amount,
        lpp_code: item.lpp_code,
        ref_product_desk: item.ref_product_desk,
        quantity: item.quantity,
      })),
      patient_analysis: this.state.patient_analysis,
    };

    if (this.state.prescriber_id !== "") {
      data.prescriber = {
        _id: this.state.prescriber_id,
        name: Util.formatFullName(prescriber.first_name, prescriber.name),
      };
    }

    if (this.state.helper_id !== "") {
      data.helper = {
        _id: this.state.helper_id,
        name: Util.formatFullName(helper.first_name, helper.name),
      };
    }
    this.props.onAddDeskAudit(data, (deskAudit) => {
      this.setState({ isLoading: true });

      const isEstimateEnabled = this.props.user.access_module_store;

      const id = "audit-pdf-" + deskAudit._id;
      const fileName = deskAudit._id;
      const template = (
        <DeskTemplatePDF
          id={id}
          patient={this.props.patient}
          prescriber={prescriber}
          patient_analysis={this.state.patient_analysis}
          deskAudit={deskAudit}
          helper={helper}
          generalSettings={this.props.generalSettings}
          auditSettings={this.props.auditDeskSettings}
          deskCategories={this.props.deskCategories}
          deskProducts={this.props.deskProducts}
          deskStore={products}
        />
      );

      const idCerfa = "cerfa_" + deskAudit._id;
      const fileNameCerfa = "cerfa_" + deskAudit._id;
      const templateCerfa = (
        <DeskTemplateCerfaPDF
          id={idCerfa}
          patient={this.props.patient}
          generalSettings={this.props.generalSettings}
          auditSettings={this.props.auditDeskSettings}
          deskStore={products}
        />
      );

      const idEstimate = "estimate_" + deskAudit._id;
      const fileNameEstimate = "estimate_" + deskAudit._id;
      const templateEstimate = (
        <DeskTemplateEstimatePDF
          id={idEstimate}
          patient={this.props.patient}
          prescriber={prescriber}
          helper={helper}
          generalSettings={this.props.generalSettings}
          deskCart={this.state.deskCart}
        />
      );

      const generatePDF = (field, value, id, fileName, css, callback) => {
        this.setState({ [field]: value }, () => {
          PDFUtil.toPDFWithPuppeteer(
            id,
            fileName,
            css,
            callback,
            {
              type: "audit",
              patientId: this.props.patient._id,
              url: "toAuditPDF",
            },
            false,
          );
        });
      };

      const generatePDFSuccessCallback = () => {
        this.props.fetchAudits();

        this.setState({ audit_id: deskAudit._id });
        this.setMode("email");
      };

      generatePDF("template", template, id, fileName, "audit_desk", () => {
        generatePDF(
          "templateCerfa",
          templateCerfa,
          idCerfa,
          fileNameCerfa,
          null,
          () => {
            if (isEstimateEnabled && this.state.deskCart.length > 0) {
              generatePDF(
                "templateEstimate",
                templateEstimate,
                idEstimate,
                fileNameEstimate,
                "estimate_desk",
                () => {
                  generatePDFSuccessCallback();
                },
              );
            } else {
              generatePDFSuccessCallback();
            }
          },
        );
      });
    });
  }

  scrollToTopAudit() {
    document.getElementById("auditPage").scrollIntoView({
      behavior: "smooth",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    switch (true) {
      case this.state.mode === "preview":
      case this.state.mode === "signature":
        this.scrollToTopAudit();
        break;
      case prevState !== this.state:
        // indexedDb can't handle stored state values containing JSX elements.
        // Remove JSX state elements before storing to indexedDb
        let stateCopy = Object.assign({}, this.state);
        stateCopy.modal = null;

        Util.fullUpdateAuditInLocalStorage(
          this.props.patient._id,
          {
            ...stateCopy,
            appVersion: process.env.REACT_APP_VERSION,
            patient_id: this.props.patient._id,
          },
          "saved_desk_audit",
        );
        break;
      default:
        // console.log("Nothing changed...");
        break;
    }
  }

  setMode(mode) {
    this.setState({ mode });
  }

  sendEmails(emails, audit) {
    // UNUSED ?

    if (emails.length > 0) {
      const data = {
        patientId: this.props.patient._id,
        auditId: this.state.audit_id,
        emailsToSend: emails,
      };

      this.setState({
        disabled: true,
      });

      // Close the modal
      var successCallback = (response) => {
        let title = <FormattedMessage id="Mail.Sent.Audit" />;
        let content = <FormattedMessage id="Mail.Sent.Success" />;
        let variant = "success";

        if (response.data !== "OK") {
          content = <FormattedMessage id="Mail.Sent.Error" />;
          variant = "danger";
        }

        this.setState({
          modal: (
            <ConfirmationModal
              variant={variant}
              title={title}
              cancelButtonDisabled
              confirmButtonLabel={this.props.intl.formatMessage({
                id: "Close",
              })}
              onAccept={() => {
                this.props.close();
              }}
            >
              {content}
            </ConfirmationModal>
          ),
        });
      };

      this.props.onSendDeskAudit(data, successCallback);
    } else {
      this.props.close();
    }
  }

  getClient(id) {
    return this.props.clients.find((c) => c._id === id);
  }

  render() {
    const { patient } = this.props;

    const modalBackground =
      this.state.mode === "ongoing" ? { backgroundColor: "#eaeaea" } : {};

    if (!patient) return;

    return (
      <>
        <Modal
          show={true}
          onHide={() =>
            this.state.mode !== "email" ? this.close() : this.props.close()
          }
          backdrop="static"
          keyboard={false}
          size={this.state.mode === "email" ? "md" : "xl"}
        >
          {this.state.mode !== "ongoing" && (
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage
                  id={
                    this.state.mode === "email"
                      ? "Send.Report.To"
                      : "Audit.Desk"
                  }
                />
              </Modal.Title>
            </Modal.Header>
          )}

          <Modal.Body
            style={modalBackground}
            className={this.state.mode === "email" ? "" : "p-0"}
          >
            {this.state.mode !== "email" && (
              <Container fluid>
                <Row>
                  <div id="auditPage"></div>

                  {this.state.mode === "initial" && (
                    <DeskInitialPage
                      patient={patient}
                      prescribers={this.props.prescribers}
                      category_id={this.state.category_id}
                      prescriber_id={this.state.prescriber_id}
                      helpers={this.props.helpers}
                      helper_id={this.state.helper_id}
                      patient_analysis={this.state.patient_analysis}
                      setState={(state) => this.setState(state)}
                      setMode={(mode) => this.setMode(mode)}
                      savedAudit={this.props.savedAudit}
                    />
                  )}

                  {this.state.mode === "ongoing" && (
                    <DeskOngoingPage
                      patient={patient}
                      close={() => this.close()}
                      prescribers={this.props.prescribers}
                      prescriber_id={this.state.prescriber_id}
                      helpers={this.props.helpers}
                      helper_id={this.state.helper_id}
                      category_id={this.state.category_id}
                      product_id={this.state.product_id}
                      deskStore={this.state.deskStore}
                      deskCart={this.state.deskCart}
                      setState={(state) => this.setState(state)}
                      setMode={(mode, category_id) =>
                        this.setMode(mode, category_id)
                      }
                      onDeskStore={(product, state, callback) =>
                        this.onDeskStore(product, state, callback)
                      }
                      delDeskStore={(product, state, callback) =>
                        this.delDeskStore(product, state, callback)
                      }
                      addAudit={() => this.addAudit()}
                      template={this.state.template}
                      initialCategoryId={this.state.initialCategoryId}
                      mandatoryCategoryId={this.state.mandatoryCategoryId}
                      categoriesVisited={this.state.categoriesVisited}
                      templateCerfa={this.state.templateCerfa}
                      templateEstimate={this.state.templateEstimate}
                      isLoading={this.state.isLoading}
                      isBackup={Object.keys(this.state.deskStore).length > 0}
                      addToCart={(product, callback) =>
                        this.addToCart(product, callback)
                      }
                      deleteFromCart={(product, callback) =>
                        this.deleteFromCart(product, callback)
                      }
                      updateCartProductQuantity={(product, number) =>
                        this.updateCartProductQuantity(product, number)
                      }
                    />
                  )}
                </Row>
              </Container>
            )}
            {this.state.mode === "email" && (
              <EmailModal
                patient={patient}
                prescribers={this.props.prescribers}
                helpers={this.props.helpers}
                prescriber={this.getClient(this.state.prescriber_id)}
                helper={this.getClient(this.state.helper_id)}
                clients={this.props.clients}
                sendEmails={(emails, audit) => this.sendEmails(emails, audit)}
                close={() => this.props.close()}
              />
            )}
          </Modal.Body>
          {this.state.modal}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clients: state.clients,
    helpers: Util.filterClients(state.clients, "helper"),
    prescribers: Util.filterClients(state.clients, "prescriber"),
    generalSettings: state.generalSettings,
    auditDeskSettings: state.auditDeskSettings,
    company: state.company,
    deskCategories: state.deskCategories,
    deskProducts: state.deskProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDeskAudit: (data, successCallback) =>
      dispatch(addDeskAudit(data, successCallback)),
    onSendDeskAudit: (data, successCallback) =>
      dispatch(sendDeskAudit(data, successCallback)),
    onGetDeskCategories: () => dispatch(getDeskCategories()),
    onGetDeskProducts: () => dispatch(getDeskProducts()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddPatientDeskModal));
