import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Alert, Modal } from "react-bootstrap";
import "./Changelog.scss";
import Icon from "../sub/Icon";
import MenuButton from "../sub/bootstrap/MenuButton";

class Changelog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  hideModal() {
    this.setState({ show: false }, () =>
      setTimeout(() => {
        this.props.close();
      }, 100),
    );
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={() => this.hideModal()}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notes de version PHARMA MAD (pour Desktop)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="changelog pe-2 pt-3">
            <Alert variant="info" className="mb-5">
              <Icon icon="fa-regular fa-circle-question" className="me-2" />
              Faites défiler le contenu de cette fenêtre pour en apprendre
              davantage sur l'historique des évolutions et des corrections de
              cette application.
            </Alert>
            <h4>3.0.3 (XX/XX/2024)</h4>
            <ul>
              <li>
                Refonte technique de la gestion des audits comptoir
                <span className="jira-issue pharma">527</span>
              </li>
              <li>
                Ajout d'un filtrage sur la sous famille lors du calcul de marge
                sur l'ensemble des produits d'une famille
                <span className="jira-issue pharma">513</span>
              </li>
              <li>
                Gestion des commandes depuis la borne
                <span className="jira-issue pharma">451</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">551</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">549</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">548</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">547</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">546</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">545</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">544</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">541</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">540</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">531</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">519</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">518</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">480</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">478</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>3.0.2 (11/09/2024)</h4>
            <ul>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">534</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">532</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">530</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">529</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">525</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>3.0.1 (10/09/2024)</h4>
            <ul>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">528</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">523</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">514</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">512</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">510</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">509</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">508</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">507</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">506</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>3.0.0 (03/09/2024)</h4>
            <ul>
              <li>
                Refonte complète de l'interface
                <span className="jira-issue pharma">481</span>
                <span className="jira-issue pharma">437</span>
              </li>
              <li>
                Gestion des imports/exports
                <ul>
                  <li>
                    Ajout de la possibilité d'importer des types de produits
                    dans la configuration des audits à domicile via un fichier
                    Excel
                    <span className="jira-issue pharma">448</span>
                  </li>
                  <li>
                    Ajout de la possibilité d'exporter dans un fichier Excel les
                    types de produits présents dans la configuration des audits
                    à domicile
                    <span className="jira-issue pharma">447</span>
                  </li>
                  <li>
                    Ajout de la possibilité d'importer des types de produits
                    dans la configuration des audits comptoir via un fichier
                    Excel
                    <span className="jira-issue pharma">446</span>
                  </li>
                  <li>
                    Ajout de la possibilité d'exporter dans un fichier Excel les
                    types de produits présents dans la configuration des audits
                    comptoir
                    <span className="jira-issue pharma">445</span>
                  </li>
                  <li>
                    Ajout de l'import en masse des clients à partir d'un fichier
                    Excel
                    <span className="jira-issue pharma">435</span>
                  </li>
                </ul>
              </li>
              <li>
                Gestion des catalogues
                <ul>
                  <li>
                    Prise en charge d'un éventuel prix limite de vente TTC par
                    produit
                    <span className="jira-issue pharma">429</span>
                  </li>
                  <li>
                    Ajout de la possibilité d'appliquer un calcul de marge sur
                    l'ensemble des produits d'une famille
                    <span className="jira-issue pharma">428</span>
                  </li>
                </ul>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">504</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">502</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">501</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">500</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">498</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">497</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">494</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">493</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">492</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">491</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">490</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">486</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">485</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">484</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">482</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">477</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">474</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">473</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">470</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">469</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">468</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">467</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">450</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">449</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">443</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">444</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">312</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>2.0.2 (18/07/2024)</h4>
            <ul>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">442</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">441</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">440</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">439</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>2.0.1 (16/07/2024)</h4>
            <ul>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">438</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">432</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>2.0.0 (13/07/2024)</h4>
            <ul>
              <li>
                Ajout du calcul du taux de marge pour les clients disposant du
                module STORE
                <span className="jira-issue pharma">374</span>
              </li>
              <li>
                L'audit comptoir peut être valorisé (évaluation financière) pour
                les clients disposant du module STORE
                <span className="jira-issue pharma">166</span>
              </li>
              <li>
                Ajout de la gestion des familles de catalogues
                <span className="jira-issue pharma">358</span>
                <span className="jira-issue pharma">357</span>
              </li>
              <li>
                Permettre la suppression des commandes dont le statut est
                "nouvelle"
                <span className="jira-issue pharma">289</span>
              </li>
              <li>
                Permettre l'export PDF d'un produit de catalogue
                <span className="jira-issue pharma">247</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">430</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">427</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">425</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">424</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">423</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">422</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">421</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">418</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">416</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">414</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">413</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">412</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">410</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">409</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">406</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">405</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">404</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">403</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">402</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">401</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">400</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">399</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">396</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">395</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">394</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">393</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">392</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">391</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">390</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">389</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">388</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">387</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">386</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">382</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">381</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">379</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">377</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">376</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">375</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">373</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">372</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">371</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">370</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">369</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">366</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">361</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">359</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">356</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">346</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">288</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">273</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">223</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">204</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">172</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">162</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.7.1 (25/03/2024)</h4>
            <ul>
              <li>
                Remplacement du délimiteur "-" par "##" pour identifier les
                déclinaisons de produits du catalogue
                <span className="jira-issue pharma">347</span>
              </li>
              <li>
                Évolution de la nomenclature des références commandes
                <span className="jira-issue pharma">340</span>
              </li>
              <li>
                Meilleure gestion de l'ajout et de la suppression des visuels de
                familles dans les catalogues
                <span className="jira-issue pharma">323</span>
              </li>
              <li>
                Les collaborateurs ont désormais accès à la gestion de tous les
                clients et patients
                <span className="jira-issue pharma">261</span>
              </li>
              <li>
                Ajout d'un espace de coordination entre professionnels
                <ul>
                  <li>
                    <span className="jira-issue pharma">250</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">251</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">252</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">253</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">254</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">255</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">256</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">258</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">268</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">269</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">270</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">271</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">317</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">320</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">322</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">327</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">328</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">333</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">345</span>
                  </li>
                </ul>
              </li>
              <li>
                Refonte complète du module DESK
                <ul>
                  <li>
                    <span className="jira-issue pharma">338</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">335</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">321</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">314</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">313</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">311</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">310</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">309</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">308</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">307</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">305</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">304</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">301</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">296</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">292</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">286</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">285</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">281</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">267</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">263</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">262</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">249</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">243</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">221</span>
                  </li>
                </ul>
              </li>
              <li>
                Permettre le filtrage des produits du catalogue par
                sous-familles
                <span className="jira-issue pharma">246</span>
              </li>
              <li>
                Définir une date de fin pour un catalogue est désormais
                facultatif
                <span className="jira-issue pharma">244</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">354</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">352</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">351</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">350</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">349</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">348</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">344</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">343</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">342</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">334</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">332</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">324</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">316</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">315</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">299</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">293</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">287</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">283</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">282</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">277</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">275</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">265</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">259</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">245</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">241</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">234</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">230</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">227</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">220</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">201</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.6.3 (25/01/2024)</h4>
            <ul>
              <li>
                Augmentation de la taille limite des documents téléchargeables
                (20 Mo au lieu de 6)
                <span className="jira-issue pharma">236</span>
              </li>
              <li>
                Correction d'une erreur empêchant le téléchargement du document
                CERFA des audits<span className="jira-issue pharma">235</span>
              </li>
              <li>
                Permettre de modifier l'affichage des résultats (pagination)
                <span className="jira-issue pharma">205</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">239</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">238</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">232</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">213</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">197</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">186</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.6.2 (22/01/2024)</h4>
            <ul>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">224</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">222</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">218</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">217</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">216</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.6.1 (17/01/2024)</h4>
            <ul>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">215</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">203</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">200</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">198</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">195</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">191</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">185</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">181</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">180</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">161</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">144</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">77</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.6.0 (28/12/2023)</h4>
            <ul>
              <li>
                Les audits DESK peuvent désormais être réalisés depuis
                l'interface web <span className="jira-issue pharma">163</span>
              </li>
              <li>
                Interfaçage avec LOMACO ON LINE
                <span className="jira-issue">511</span>
                <span className="jira-issue">578</span>
                <ul>
                  <li>Possibilité de lier les comptes clients</li>
                  <li>Possibilité de lier les mercuriales</li>
                  <li>Synchronisation automatique des états de commandes</li>
                </ul>
              </li>
              <li>
                Ajout d'un écran permettant de visualiser les prochains
                renouvellements d'équipements patient
                <span className="jira-issue">499</span>
              </li>
              <li>
                Implémentation d'une gestion de documents partagés
                <span className="jira-issue">489</span>
              </li>
              <li>
                Ajout d'une couche de sécurité dans les envois de documents
                sensibles par courriel<span className="jira-issue">141</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue pharma">187</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">184</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">178</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">177</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">174</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">173</span>
                  </li>
                  <li>
                    <span className="jira-issue pharma">167</span>
                  </li>
                  <li>
                    <span className="jira-issue">597</span>
                  </li>
                  <li>
                    <span className="jira-issue">596</span>
                  </li>
                  <li>
                    <span className="jira-issue">594</span>
                  </li>
                  <li>
                    <span className="jira-issue">592</span>
                  </li>
                  <li>
                    <span className="jira-issue">583</span>
                  </li>
                  <li>
                    <span className="jira-issue">582</span>
                  </li>
                  <li>
                    <span className="jira-issue">581</span>
                  </li>
                  <li>
                    <span className="jira-issue">579</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.5.1 (13/10/2023)</h4>
            <ul>
              <li>
                Afficher la date de la commande dans les PDF
                <span className="jira-issue">574</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">577</span>
                  </li>
                  <li>
                    <span className="jira-issue">576</span>
                  </li>
                  <li>
                    <span className="jira-issue">568</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.5.0 (01/10/2023)</h4>
            <ul>
              <li>
                Permettre aux patients et aux aidants d'accèder à un espace
                personnel<span className="jira-issue">540</span>
              </li>
              <li>
                Permettre la suppression d'un compte collaborateur
                <span className="jira-issue">530</span>
              </li>
              <li>
                Possibilité de classer et de modifier les points de contrôle
                pour les dispositifs<span className="jira-issue">528</span>
              </li>
              <li>
                Synchronisation automatique avec MUST G5 des états de commandes
                (en déploiement)<span className="jira-issue">504</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">571</span>
                  </li>
                  <li>
                    <span className="jira-issue">569</span>
                  </li>
                  <li>
                    <span className="jira-issue">565</span>
                  </li>
                  <li>
                    <span className="jira-issue">564</span>
                  </li>
                  <li>
                    <span className="jira-issue">563</span>
                  </li>
                  <li>
                    <span className="jira-issue">562</span>
                  </li>
                  <li>
                    <span className="jira-issue">558</span>
                  </li>
                  <li>
                    <span className="jira-issue">556</span>
                  </li>
                  <li>
                    <span className="jira-issue">554</span>
                  </li>
                  <li>
                    <span className="jira-issue">553</span>
                  </li>
                  <li>
                    <span className="jira-issue">552</span>
                  </li>
                  <li>
                    <span className="jira-issue">551</span>
                  </li>
                  <li>
                    <span className="jira-issue">548</span>
                  </li>
                  <li>
                    <span className="jira-issue">547</span>
                  </li>
                  <li>
                    <span className="jira-issue">546</span>
                  </li>
                  <li>
                    <span className="jira-issue">545</span>
                  </li>
                  <li>
                    <span className="jira-issue">544</span>
                  </li>
                  <li>
                    <span className="jira-issue">543</span>
                  </li>
                  <li>
                    <span className="jira-issue">542</span>
                  </li>
                  <li>
                    <span className="jira-issue">541</span>
                  </li>
                  <li>
                    <span className="jira-issue">537</span>
                  </li>
                  <li>
                    <span className="jira-issue">536</span>
                  </li>
                  <li>
                    <span className="jira-issue">533</span>
                  </li>
                  <li>
                    <span className="jira-issue">532</span>
                  </li>
                  <li>
                    <span className="jira-issue">529</span>
                  </li>
                  <li>
                    <span className="jira-issue">517</span>
                  </li>
                  <li>
                    <span className="jira-issue">488</span>
                  </li>
                  <li>
                    <span className="jira-issue">487</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.4.2 (12/07/2023)</h4>
            <ul>
              <li>
                Correction d'une erreur bloquant l'envoi de notifications de
                nouvelles commandes par email
                <span className="jira-issue">525</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">527</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.4.1 (10/07/2023)</h4>
            <ul>
              <li>
                Correction d'une erreur potentielle d'inversion des quantités
                commandées entre deux articles synchronisés avec MUST G5
                <span className="jira-issue">517</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">519</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.4.0 (07/07/2023)</h4>
            <ul>
              <li>
                Prise en charge des déclinaisons MUST G5 en synchronisation pour
                les mercuriales<span className="jira-issue">444</span>
              </li>
              <li>
                Amélioration du fichier d'export sur les erreurs de
                synchronisation
                <span className="jira-issue">495</span>
              </li>
              <li>
                Refonte du menu d'action associé à la gestion des patients
                <span className="jira-issue">505</span>
              </li>
              <li>
                Notification par mail des demandes de nouvel audit
                <span className="jira-issue">480</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">520</span>
                  </li>
                  <li>
                    <span className="jira-issue">512</span>
                  </li>
                  <li>
                    <span className="jira-issue">508</span>
                  </li>
                  <li>
                    <span className="jira-issue">507</span>
                  </li>
                  <li>
                    <span className="jira-issue">492</span>
                  </li>
                  <li>
                    <span className="jira-issue">485</span>
                  </li>
                  <li>
                    <span className="jira-issue">486</span>
                  </li>
                  <li>
                    <span className="jira-issue">485</span>
                  </li>
                  <li>
                    <span className="jira-issue">484</span>
                  </li>
                  <li>
                    <span className="jira-issue">483</span>
                  </li>
                  <li>
                    <span className="jira-issue">482</span>
                  </li>
                  <li>
                    <span className="jira-issue">445</span>
                  </li>
                  <li>
                    <span className="jira-issue">431</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.3.4 (14/02/2023)</h4>
            <ul>
              <li>
                Côté prescripteur, certains audits patient ne s'affichent pas
                <span className="jira-issue">478</span>
              </li>
              <li>
                Le nom de la mercuriale associée à un prescripteur ne s'affiche
                plus après mise à jour<span className="jira-issue">477</span>
              </li>
              <li>
                Correction de l'affichage de certains tarifs HT à quatre
                décimales
                <span className="jira-issue">475</span>
              </li>
              <li>
                L'import massif de patients échoue et renvoie une erreur 500
                <span className="jira-issue">473</span>
              </li>
              <li>
                Correction d'un défaut d'affectation d'une mercuriale à
                l'ensemble des clients concernés lors de sa mise à jour
                <span className="jira-issue">472</span>
              </li>
              <li>
                Correction d'un bug empêchant de correctement sélectionner un
                prescripteur principal<span className="jira-issue">464</span>
              </li>
            </ul>
            <h4>1.3.3 (18/01/2023)</h4>
            <ul>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">473</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.3.2 (15/12/2022)</h4>
            <ul>
              <li>
                Possibilité d'ajouter des documents de type images à la fiche
                patient et correction d'un bug empêchant la fermeture de la
                fenêtre d'import<span className="jira-issue">462</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">461</span>
                  </li>
                  <li>
                    <span className="jira-issue">459</span>
                  </li>
                  <li>
                    <span className="jira-issue">366</span>
                  </li>
                  <li>
                    <span className="jira-issue">364</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.3.1 (19/10/2022)</h4>
            <ul>
              <li>
                Correction d'une régression empêchant de scinder les commandes
                <span className="jira-issue">458</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">442</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.3.0 (18/10/2022)</h4>
            <ul>
              <li>
                Mise à niveau de certaines interfaces
                <span className="jira-issue">410</span>
              </li>
              <li>
                Amélioration de la pertinence des résultats dans le moteur de
                recherche des produits de Mercuriales
                <span className="jira-issue">375</span>
              </li>
              <li>
                Le PSDM peut afficher la liste des patients associés à un
                prescripteur<span className="jira-issue">377</span>
              </li>
              <li>
                Gestion de la suppression des compte-rendus de maintenances
                <span className="jira-issue">347</span>
              </li>
              <li>
                Amélioration des performances sur la récupération de données
                (compression de flux)<span className="jira-issue">329</span>
              </li>
              <li>
                Le résultat du moteur de recherche n'affiche pas la bonne
                déclinaison<span className="jira-issue">439</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">513</span>
                  </li>
                  <li>
                    <span className="jira-issue">455</span>
                  </li>
                  <li>
                    <span className="jira-issue">449</span>
                  </li>
                  <li>
                    <span className="jira-issue">443</span>
                  </li>
                  <li>
                    <span className="jira-issue">424</span>
                  </li>
                  <li>
                    <span className="jira-issue">407</span>
                  </li>
                  <li>
                    <span className="jira-issue">402</span>
                  </li>
                  <li>
                    <span className="jira-issue">357</span>
                  </li>
                  <li>
                    <span className="jira-issue">328</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.2.0 (03/09/2022)</h4>
            <ul>
              <li>
                Mise à niveau du serveur et prise en charge des dernières
                versions Android
              </li>
              <ul>
                <span className="jira-issue e4bizz">39</span>
                <span className="jira-issue e4bizz">38</span>
                <span className="jira-issue e4bizz">37</span>
                <span className="jira-issue e4bizz">36</span>
                <span className="jira-issue e4bizz">35</span>
                <span className="jira-issue e4bizz">30</span>
                <span className="jira-issue e4bizz">28</span>
                <span className="jira-issue e4bizz">26</span>
              </ul>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">430</span>
                  </li>
                  <li>
                    <span className="jira-issue">428</span>
                  </li>
                  <li>
                    <span className="jira-issue">427</span>
                  </li>
                  <li>
                    <span className="jira-issue">426</span>
                  </li>
                  <li>
                    <span className="jira-issue">422</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.1.1 (17/08/2022)</h4>
            <ul>
              <li>
                Correction d'un erreur empêchant le bon enregistrement du
                fichier PDF des maintenances depuis la tablette
                <span className="jira-issue">425</span>
              </li>
            </ul>
            <h4>1.1.0 (10/08/2022)</h4>
            <ul>
              <li>
                Prérequis à l'implémentation de nouvelles fonctionnalités
                <span className="jira-issue">415</span>
              </li>
              <li>
                Correction d'un affichage de suivi de synchronisation des
                mercuriales avec MUST<span className="jira-issue">420</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">423</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.0.1 (21/07/2022)</h4>
            <ul>
              <li>
                Correction d'un bug relatif aux quantités sur les déclinaisons
                de produits dans les paniers de commandes
                <span className="jira-issue">411</span>
              </li>
              <li>
                La liste des prescripteurs et des commandes est à nouveau
                disponible aux collaborateurs
                <span className="jira-issue">409</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">412</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>1.0.0 (13/07/2022)</h4>
            <ul>
              <li>
                Refonte majeure de la gestion des mercuriales et des commandes
                pour permettre :<span className="jira-issue">397</span>
                <ul>
                  <li>
                    La modification des tarifs des produits de la mercuriale à
                    la ligne
                  </li>
                  <li>
                    Le traitement des commandes en reliquat sur des mercuriales
                    mise à jour ou expirées
                  </li>
                </ul>
              </li>
              <li>
                Comme pour les mercuriales, les patients peuvent désormais être
                importés à partir d'un modèle Excel
                <span className="jira-issue">369</span>
              </li>
              <li>Optimisations des performances</li>
              <ul>
                <li>
                  Récupération de données de mercuriales
                  <span className="jira-issue">331</span>
                  <span className="jira-issue">333</span>
                </li>
                <li>
                  Récupération de données prescripteurs
                  <span className="jira-issue">322</span>
                </li>
                <li>
                  Récupération de données patients
                  <span className="jira-issue">321</span>
                </li>
              </ul>
              <li>
                Ajout d'une case à cocher pour offrir une alternative aux
                patients ayant des difficultés à signer les audits
                <span className="jira-issue">383</span>
              </li>
              <li>
                Fiche patient/prescripteur: Les noms de villes sont désormais
                suggérés à partir du code postal saisi
                <span className="jira-issue">335</span>
                <span className="jira-issue">379</span>
              </li>
              <li>
                Refonte du formulaire de création d'un prescripteur
                <span className="jira-issue">378</span>
              </li>
              <li>
                Fiche patient/prescripteur: Ajout d'un champ complément
                d'adresse
                <span className="jira-issue">341</span>
              </li>
              <li>
                Fiche patient: Possibilité de saisir un commentaire privé
                <span className="jira-issue">342</span>
              </li>
              <li>
                Refonte de la gestion des visuels produits (banque d'images)
                <span className="jira-issue">359</span>
              </li>
              <li>
                Meilleure prise en charge de la vérification des adresses mail
                <span className="jira-issue">358</span>
              </li>
              <li>
                Ajout des informations client dans les PDF de commandes
                <span className="jira-issue">353</span>
              </li>
              <li>
                Les dates de première installation et de prescription ne sont
                plus obligatoires à la saisie d'un dispositif patient
                <span className="jira-issue">350</span>
              </li>
              <li>
                Possibilité de supprimer une maintenance réalisée sur un
                dispositif
                <span className="jira-issue">311</span>
              </li>
              <li>
                Gestion améliorée des pièces jointes en annexe des devis
                <span className="jira-issue">289</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">405</span>
                  </li>
                  <li>
                    <span className="jira-issue">404</span>
                  </li>
                  <li>
                    <span className="jira-issue">400</span>
                  </li>
                  <li>
                    <span className="jira-issue">394</span>
                  </li>
                  <li>
                    <span className="jira-issue">393</span>
                  </li>
                  <li>
                    <span className="jira-issue">390</span>
                  </li>
                  <li>
                    <span className="jira-issue">389</span>
                  </li>
                  <li>
                    <span className="jira-issue">380</span>
                  </li>
                  <li>
                    <span className="jira-issue">354</span>
                  </li>
                  <li>
                    <span className="jira-issue">348</span>
                  </li>
                  <li>
                    <span className="jira-issue">344</span>
                  </li>
                  <li>
                    <span className="jira-issue">343</span>
                  </li>
                  <li>
                    <span className="jira-issue">336</span>
                  </li>
                  <li>
                    <span className="jira-issue">334</span>
                  </li>
                  <li>
                    <span className="jira-issue">325</span>
                  </li>
                  <li>
                    <span className="jira-issue">324</span>
                  </li>
                  <li>
                    <span className="jira-issue">323</span>
                  </li>
                  <li>
                    <span className="jira-issue">320</span>
                  </li>
                  <li>
                    <span className="jira-issue">318</span>
                  </li>
                  <li>
                    <span className="jira-issue">175</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.10.1 (14/01/2022)</h4>
            <ul>
              <li>
                Afficher les visuels de déclinaisons produit si disponibles
                <span className="jira-issue">327</span>
              </li>
              <li>
                Correction d'une régression sur l'affichage de certains montants
                à plus de deux décimales<span className="jira-issue">295</span>
              </li>
              <li>
                Correction d'une régression sur règle d'arrondi de TVA sur les
                commandes<span className="jira-issue">270</span>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">326</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.10.0 (30/12/2021)</h4>
            <ul>
              <li>
                Gestion des maintenances sur les dispositifs MAD
                <span className="jira-issue">285</span>
                <span className="jira-issue">292</span>
                <ul>
                  <li>
                    Possibilité d'affecter un numéro interne et un numéro de
                    série par dispositif
                  </li>
                  <li>
                    Possibilité de définir des points de contrôle pour chaque
                    type de dispositif
                  </li>
                  <li>
                    Possibilité d'affecter un type de dispositif à plusieurs
                    pièces d'audit
                  </li>
                  <li>Possibilité d'éditer un rapport de maintenance PDF</li>
                </ul>
              </li>
              <li>
                Edition de compte-rendus d'intervention au domicile
                <span className="jira-issue">264</span>
                <ul>
                  <li>
                    Possibilité d'éditer un rapport PDF d'installation, de
                    reprise ou d'échange pour les dispositifs MAD
                  </li>
                  <li>Possibilité d'envoyer le rapport par courriel</li>
                </ul>
              </li>
              <li>
                Le PDF sur les nouvelles commandes ou les commandes en reliquat
                est désormais téléchargeable par le client
                <span className="jira-issue">279</span>
              </li>
              <li>
                La liste des prescripteurs a été corrigée pour l'envoi ou le
                renvoi d'un mail d'audit<span className="jira-issue">294</span>
              </li>
              <li>
                Amélioration du processus d'authentification unifiée pour
                l'application tablette
                <ul>
                  <li>
                    <span className="jira-issue">300</span>
                  </li>
                  <li>
                    <span className="jira-issue">299</span>
                  </li>
                  <li>
                    <span className="jira-issue">298</span>
                  </li>
                  <li>
                    <span className="jira-issue">297</span>
                  </li>
                </ul>
              </li>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">296</span>
                  </li>
                  <li>
                    <span className="jira-issue">286</span>
                  </li>
                  <li>
                    <span className="jira-issue">284</span>
                  </li>
                  <li>
                    <span className="jira-issue">283</span>
                  </li>
                  <li>
                    <span className="jira-issue">268</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.9.1 (17/11/2021)</h4>
            <ul>
              <li>
                Evolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">291</span>
                  </li>
                  <li>
                    <span className="jira-issue">287</span>
                  </li>
                  <li>
                    <span className="jira-issue">276</span>
                  </li>
                  <li>
                    <span className="jira-issue">275</span>
                  </li>
                  <li>
                    <span className="jira-issue">270</span>
                  </li>
                  <li>
                    <span className="jira-issue">266</span>
                  </li>
                  <li>
                    <span className="jira-issue">265</span>
                  </li>
                  <li>
                    <span className="jira-issue">261</span>
                  </li>
                  <li>
                    <span className="jira-issue">255</span>
                  </li>
                  <li>
                    <span className="jira-issue">249</span>
                  </li>
                  <li>
                    <span className="jira-issue">241</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.9.0 (04/10/2021)</h4>
            <ul>
              <li>
                Interfaçage avec ORTHOP MUST G5
                <span className="jira-issue">232</span>
                <ul>
                  <li>Possibilité de lier les comptes clients</li>
                  <li>Possibilité de lier les mercuriales</li>
                  <li>
                    Possibilité d'envoyer automatiquement les commandes pour
                    intégration dans MUST G5
                  </li>
                </ul>
              </li>
              <li>
                Correction d'une erreur empêchant la saisie de codes postaux
                valides
                <span className="jira-issue">283</span>
              </li>
              <li>
                Autres évolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">282</span>
                  </li>
                  <li>
                    <span className="jira-issue">281</span>
                  </li>
                  <li>
                    <span className="jira-issue">278</span>
                  </li>
                  <li>
                    <span className="jira-issue">277</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.8.4 (28/09/2021)</h4>
            <ul>
              <li>
                Correction d'une erreur "not found" sur l'affichage d'un produit
                à partir du moteur de recherche côté client
                <ul>
                  <li>
                    <span className="jira-issue">272</span>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                Autres évolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">277</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.8.3 (29/07/2021)</h4>
            <ul>
              <li>
                Correction d'un problème d'affichage des images par défaut dans
                la configuration des audits
                <span className="jira-issue">269</span>
              </li>
              <li>
                Autres évolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">253</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.8.2 (22/07/2021)</h4>
            <ul>
              <li>
                Correction d'un problème de renvoi de mail pour les audits créés
                avant l'ajout du CERFA<span className="jira-issue">259</span>
              </li>
            </ul>
            <h4>0.8.1 (21/07/2021)</h4>
            <ul>
              <li>
                Autres évolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">258</span>
                  </li>
                  <li>
                    <span className="jira-issue">257</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.8.0 (21/07/2021)</h4>
            <ul>
              <li>
                Audits
                <ul>
                  <li>
                    Possibilité d'éditer un CERFA spécimen pour les matériels
                    soumis à prescription obligatoire
                    <span className="jira-issue">226</span>
                    <span className="jira-issue">245</span>
                  </li>
                  <li>
                    Possibilité de supprimer un audit depuis le web ou la
                    tablette
                    <span className="jira-issue">242</span>
                  </li>
                  <li>
                    Permettre le renvoi des audits par mail
                    <span className="jira-issue">243</span>
                  </li>
                </ul>
              </li>
              <li>
                Commandes
                <ul>
                  <li>
                    Correction d'une erreur empêchant l'affichage de l'adresse
                    du client sur le PDF dans certains cas
                    <span className="jira-issue">143</span>
                  </li>
                  <li>
                    Seules les commandes qui n'ont pas fait l'objet d'un
                    traitement par le PSDM (statut "Nouvelle") peuvent être
                    supprimées par le prescripteur
                    <span className="jira-issue">230</span>
                  </li>
                </ul>
              </li>
              <li>
                Mercuriales
                <ul>
                  <li>
                    Correction d'une possible erreur de date de début lors de la
                    mise à jour de mercuriales anticipées
                    <span className="jira-issue">160</span>
                  </li>
                </ul>
              </li>
              <li>
                Patients
                <ul>
                  <li>
                    Ajout d'un espace "Factures"
                    <span className="jira-issue">135</span>
                  </li>
                </ul>
              </li>
              <li>
                Configuration
                <ul>
                  <li>
                    Réorganisation du menu des audits
                    <span className="jira-issue">237</span>
                  </li>
                  <li>
                    Notification explicite des messages automatiques dans les
                    entêtes de courriels<span className="jira-issue">232</span>
                  </li>
                  <li>
                    Correction d'un défaut d'affichage des alertes
                    renouvellement côté prescripteur
                    <span className="jira-issue">153</span>
                  </li>
                </ul>
              </li>
              <li>
                Autres évolutions et correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">254</span>
                  </li>
                  <li>
                    <span className="jira-issue">252</span>
                  </li>
                  <li>
                    <span className="jira-issue">251</span>
                  </li>
                  <li>
                    <span className="jira-issue">248</span>
                  </li>
                  <li>
                    <span className="jira-issue">247</span>
                  </li>
                  <li>
                    <span className="jira-issue">244</span>
                  </li>
                  <li>
                    <span className="jira-issue">240</span>
                  </li>
                  <li>
                    <span className="jira-issue">238</span>
                  </li>
                  <li>
                    <span className="jira-issue">236</span>
                  </li>
                  <li>
                    <span className="jira-issue">234</span>
                  </li>
                  <li>
                    <span className="jira-issue">233</span>
                  </li>
                  <li>
                    <span className="jira-issue">231</span>
                  </li>
                  <li>
                    <span className="jira-issue">229</span>
                  </li>
                  <li>
                    <span className="jira-issue">225</span>
                  </li>
                  <li>
                    <span className="jira-issue">224</span>
                  </li>
                  <li>
                    <span className="jira-issue">223</span>
                  </li>
                  <li>
                    <span className="jira-issue">220</span>
                  </li>
                  <li>
                    <span className="jira-issue">213</span>
                  </li>
                  <li>
                    <span className="jira-issue">206</span>
                  </li>
                  <li>
                    <span className="jira-issue">132</span>
                  </li>
                </ul>
              </li>
            </ul>

            <h4>0.7.4 (21/05/2021)</h4>
            <ul>
              <li>
                Mise à jour des textes de présentation relatifs aux pièces
                d'audit
                <span className="jira-issue">218</span>
              </li>
            </ul>

            <h4>0.7.3 (14/05/2021)</h4>
            <ul>
              <li>
                Mercuriales
                <ul>
                  <li>
                    Réorganisation de l'interface pour l'administrateur PSDM
                    <span className="jira-issue">195</span>
                  </li>
                  <li>
                    Ajout d'un indicateur visuel dans la liste des prescripteurs
                    lorsqu'une mercuriale affectée a expiré
                    <span className="jira-issue">194</span>
                  </li>
                  <li>
                    Correction de la gestion du taux de TVA en fonction du
                    format trouvé dans le fichier d'import
                    <span className="jira-issue">151</span>
                  </li>
                  <li>
                    Correction de la gestion du panier en attente en cas de
                    changement de mercuriale
                    <span className="jira-issue">197</span>
                  </li>
                  <li>
                    Au moment de valider sa commande, un message explicite
                    indique au prescripteur le solde manquant en cas de minimum
                    de commande imposé<span className="jira-issue">200</span>
                  </li>
                </ul>
              </li>
              <li>
                Audits
                <ul>
                  <li>
                    Refonte de l'interface
                    <span className="jira-issue">205</span>
                    <span className="jira-issue">216</span>
                  </li>
                  <li>
                    Les audits sont désormais modifiables après l'étape de
                    prévisualisation<span className="jira-issue">203</span>
                  </li>
                  <li>
                    Reprise du PDF<span className="jira-issue">204</span>
                  </li>
                  <li>
                    Les commentaires internes sont désormais consultables depuis
                    la tablette et l'interface en ligne
                    <span className="jira-issue">201</span>
                  </li>
                  <li>
                    La déclaration du prescripteur et de l'aidant est désormais
                    facultative<span className="jira-issue">202</span>
                  </li>
                </ul>
              </li>
              <li>
                Correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">133</span>
                  </li>
                  <li>
                    <span className="jira-issue">171</span>
                  </li>
                  <li>
                    <span className="jira-issue">197</span>
                  </li>
                  <li>
                    <span className="jira-issue">198</span>
                  </li>
                  <li>
                    <span className="jira-issue">199</span>
                  </li>
                  <li>
                    <span className="jira-issue">207</span>
                  </li>
                  <li>
                    <span className="jira-issue">208</span>
                  </li>
                  <li>
                    <span className="jira-issue">209</span>
                  </li>
                  <li>
                    <span className="jira-issue">211</span>
                  </li>
                  <li>
                    <span className="jira-issue">212</span>
                  </li>
                  <li>
                    <span className="jira-issue">214</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.6.0 (25/03/2021)</h4>
            <ul>
              <li>
                Mercuriales
                <ul>
                  <li>
                    Refonte du moteur de recherche et de l'affichage des
                    produits
                    <span className="jira-issue">173</span>
                  </li>
                  <li>
                    Ajout de la gestion des produits favoris
                    <span className="jira-issue">140</span>
                  </li>
                </ul>
              </li>
              <li>
                Correctifs mineurs
                <ul>
                  <li>
                    <span className="jira-issue">188</span>
                  </li>
                  <li>
                    <span className="jira-issue">189</span>
                  </li>
                  <li>
                    <span className="jira-issue">190</span>
                  </li>
                </ul>
              </li>
            </ul>
            <h4>0.5.0 (16/02/2021)</h4>
            <ul>
              <li>
                Mise à niveau du module d'importation de mercuriales
                <span className="jira-issue">166</span>
              </li>
              <li>
                Le cas échéant, la mercuriale associée s'affiche dans le tableau
                récapitulatif des prescripteurs
                <span className="jira-issue">159</span>
              </li>
              <li>
                En cas de mise à jour d'une mercuriale, la réaffectation au(x)
                client(s) associé(s) se fait automatiquement
                <span className="jira-issue">157</span>
              </li>
            </ul>
            <h4>0.4.3 (14/12/2020)</h4>
            <ul>
              <li>
                Le renouvellement de location de matériel médical est désormais
                possible jusqu'à 12 mois
              </li>
              <li>
                Correction de problèmes d'import d'images de mercuriales avec
                certaines références produits
              </li>
              <li>
                Correction d'incohérences de couleurs sur les dates d'expiration
                de certaines locations
              </li>
            </ul>
            <h4>0.4.2 (23/11/2020)</h4>
            <ul>
              <li>
                Correction de l'affichage des dates dans la gestion des
                équipements de la fiche patient
              </li>
            </ul>
            <h4>0.4.1 (09/11/2020)</h4>
            <ul>
              <li>Tests complémentaires sur les données saisies</li>
            </ul>
            <h4>0.4.0 (26/08/2020)</h4>
            <ul>
              <li>
                Simplification des modalités de connexion (mémorisation
                d'identifiant, accès direct pour les clients)
              </li>
              <li>
                Consolidation de l'interface graphique entre prestataire et
                client
              </li>
              <li>
                Ajout d'indicateurs visuels (codes couleurs) sur les statuts
              </li>
              <li>
                Amélioration de l'affichage des tableaux et de la pagination
              </li>
              <li>Ajout de fonctionalités de filtrage de résultats</li>
            </ul>
            <h4>0.2.7 (10/06/2020)</h4>
            <ul>
              <li>Correctifs d'affichage mineurs</li>
            </ul>
            <h4>0.2.6 (08/06/2020)</h4>
            <ul>
              <li>Diffusion des notes de version</li>
            </ul>
            <h4>0.2.5 (08/06/2020)</h4>
            <ul>
              <li>
                Amélioration du processus d'importation des mercuriales et du
                système de notification en cas d'erreur
              </li>
            </ul>
            <h4>0.2.4 (29/05/2020)</h4>
            <ul>
              <li>
                Amélioration de la gestion de la session permettant de
                rafraichir la page sans être déconnecté de l'application
              </li>
              <li>Correctifs mineurs</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <MenuButton onClick={() => this.hideModal()}>
            <FormattedMessage id="Close" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Changelog));
