import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Alert, Col, InputGroup, Modal, Row } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
import InputTextElement from "../sub/InputTextElement";
import InputLength from "../../enums/InputLength";
import TextareaCounter from "../sub/TextareaCounter";
import { connect } from "react-redux";
import {
  addProductAdmin,
  checkIfRefFrnExists,
  updateProductAdmin,
} from "../../actions/products/products";
import APIUrl from "../../APIUrl";
import FileDropZone from "../sub/FileDropZone";
import UploadProductImgModal from "../mercurials/UploadProductImgModal";
import { NotificationManager } from "react-notifications";
import {
  addProductsImgs,
  removeProductsImgs,
} from "../../actions/mercurials/mercurials";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import Maths from "../../util/Maths";
import ComboBox from "../sub/ComboBox";
import PriceUtil from "../../util/PriceUtil";
import InputNumber from "../sub/fields/input/InputNumber";
import MenuButton from "../sub/bootstrap/MenuButton";
import Util from "../../util/Util";

class ProductModal extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.product) {
      const modelSubstrings = this.props.product.designation.split(
        process.env.REACT_APP_PRODUCT_DELIMITER,
      );
      const model =
        modelSubstrings && modelSubstrings.length > 1
          ? modelSubstrings[modelSubstrings.length - 1].trim()
          : "";

      const designation = this.props.product.designation
        .split(process.env.REACT_APP_PRODUCT_DELIMITER)[0]
        .trim();

      if (!this.props.isAddDeclination) {
        this.state = {
          modal: null,
          fam: this.props.product.fam || "1. GB",
          famille: this.props.product.famille || this.props.famille.name,
          sous_famille: this.props.product.sous_famille || "",
          fournisseur: this.props.product.fournisseur || "",
          ref_frn: this.props.product.ref_frn || "",
          lpp_amount: this.props.product.lpp_amount || 0,
          lpp_code: this.props.product.lpp_code || "",
          ref: this.props.product.ref || "",
          designation: designation || "",
          model: model || "",
          caracteristiques: this.props.product.caracteristiques || "",
          tva: this.props.product.tva * 100 || "",
          prix_achat_ht: this.props.product.prix_achat_ht || "",
          taux_marge: this.props.product.taux_marge || "",
          taux_marque: this.props.product.taux_marque || "",
          prix_u_ht_emera: this.props.product.prix_u_ht_emera || "",
          prix_limite_ttc: this.props.product.prix_limite_ttc || "",
          prix_ttc:
            this.props.product.prix_u_ht_emera && this.props.product.tva
              ? PriceUtil.priceTtc(this.props.product, 1, 2)
              : "",
          min_cde: this.props.product.min_cde || 1,
          unite_de_vente: this.props.product.unite_de_vente || "",
          customImg: this.props.product.customImg || false,
          subCategories:
            this.props.subCategories.map((c) => {
              return { _id: c, name: c };
            }) || [],
          refFrnError: "",
          margeError: "",
          priceLimitError: "",
        };
      } else {
        this.state = {
          modal: null,
          fam: this.props.product.fam || "1. GB",
          famille: this.props.product.famille || this.props.famille.name,
          sous_famille: this.props.product.sous_famille || "",
          fournisseur: this.props.product.fournisseur || "",
          ref_frn: "",
          lpp_amount: 0,
          lpp_code: "",
          ref: "",
          designation: designation || "",
          model: "",
          caracteristiques: this.props.product.caracteristiques || "",
          tva: this.props.product.tva * 100 || "",
          prix_achat_ht: "",
          taux_marge: "",
          taux_marque: "",
          prix_u_ht_emera: "",
          prix_limite_ttc: "",
          prix_ttc: "",
          min_cde: 1,
          unite_de_vente: "",
          customImg: false,
          subCategories:
            this.props.subCategories.map((c) => {
              return { _id: c, name: c };
            }) || [],
          refFrnError: "",
          margeError: "",
          priceLimitError: "",
        };
      }
    } else {
      this.state = {
        modal: null,
        file: [],
        fam: "1. GB",
        famille: this.props.famille.name,
        sous_famille: "",
        fournisseur: "",
        ref_frn: "",
        lpp_amount: 0,
        lpp_code: "",
        ref: "",
        designation: "",
        caracteristiques: "",
        tva: "",
        prix_achat_ht: "",
        taux_marge: "",
        taux_marque: "",
        prix_u_ht_emera: "",
        prix_limite_ttc: "",
        prix_ttc: "",
        min_cde: 1,
        unite_de_vente: "",
        subCategories:
          this.props.subCategories.map((c) => {
            return { _id: c, name: c };
          }) || [],
        refFrnError: "",
        margeError: "",
        priceLimitError: "",
      };
    }
  }

  formatTva(tva) {
    return tva > 1 ? tva / 100 : tva;
  }

  onSubmit() {
    if (this.disabled()) return;

    let product = {
      fam: this.state.fam,
      famille: this.state.famille,
      famille_id: this.props.famille._id,
      sous_famille: this.state.sous_famille,
      caracteristiques: this.state.caracteristiques,
      designation:
        this.state.model && !Util.emptyString(this.state.model)
          ? `${this.state.designation} ${process.env.REACT_APP_PRODUCT_DELIMITER} ${this.state.model}`
          : this.state.designation,
      unite_de_vente: this.state.unite_de_vente,
      min_cde: this.state.min_cde,
      ref: this.state.ref,
      prix_achat_ht: this.state.prix_achat_ht,
      taux_marge: this.state.taux_marge,
      taux_marque: this.state.taux_marque,
      prix_limite_ttc: this.state.prix_limite_ttc,
      prix_u_ht_emera: this.state.prix_u_ht_emera,
      tva: this.formatTva(this.state.tva),
      fournisseur: this.state.fournisseur,
      ref_frn: this.state.ref_frn,
      lpp_code: this.state.lpp_code,
      lpp_amount: this.state.lpp_amount,
      mercurial_id: this.props.mercurialId,
    };

    const isMainProduct =
      this.props.product && !this.props.product.parentProductRefFrn;

    if (isMainProduct) {
      product.formats = this.props.product.formats;
    }

    if (this.props.product && !this.props.isAddDeclination) {
      product._id = this.props.product._id;

      if (this.props.product.prix_u_ht_emera !== this.state.prix_u_ht_emera) {
        return this.openConfModal(
          this.props.product.prix_u_ht_emera,
          this.state.prix_u_ht_emera,
          () =>
            this.props.onUpdateProductAdmin(product, () =>
              this.props.closeModal(),
            ),
        );
      }

      return this.props.onUpdateProductAdmin(product, () =>
        this.props.closeModal(),
      );
    }

    const sendImage = (file, successCallback) => {
      if (!file || file.length === 0) return successCallback();

      let formData = new FormData();

      // Tell the backend that we want to use a custom images even if a database image exists
      formData.append("useCustomImage", true);

      for (const img of file) {
        formData.append(this.state.ref_frn, img);
      }

      this.props.onAddProductsImgs(
        this.props.mercurialId,
        formData,
        successCallback,
      );
    };

    this.props.onAddProductAdmin(
      product,
      sendImage(this.state.file, () => this.props.closeModal()),
    );
  }

  disabled() {
    return (
      this.state.famille === "" ||
      this.state.sous_famille === "" ||
      this.state.fournisseur === "" ||
      this.state.ref_frn === "" ||
      this.state.ref === "" ||
      this.state.designation === "" ||
      !this.state.tva ||
      !this.state.prix_u_ht_emera ||
      this.state.unite_de_vente === "" ||
      this.state.refFrnError !== "" ||
      this.state.priceLimitError !== ""
    );
  }

  closeModal(callback) {
    this.setState({ modal: null }, callback);
  }

  uploadImage(productRef, productImage) {
    const sendProductImage = (successCallback) => {
      if (!productImage || productImage.length === 0) return successCallback();

      let formData = new FormData();

      // Tell the backend that we want to use a custom images even if a database image exists
      formData.append("useCustomImage", true);

      for (const img of productImage) {
        formData.append(productRef, img);
      }

      this.props.onAddProductsImgs(this.props.mercurialId, formData, () =>
        this.setState({ customImg: true }, successCallback),
      );
    };

    const displayNotification = () => {
      NotificationManager.success(
        <FormattedMessage id="Product.Image.Updated" />,
      );
    };

    sendProductImage(() => this.closeModal(displayNotification));
  }

  removeImage(productRef) {
    const removeProductImage = (successCallback) => {
      if (!productRef) return successCallback();
      const data = { removeRef: productRef };
      this.props.onRemoveProductsImgs(this.props.mercurialId, data, () =>
        this.setState({ customImg: false }, successCallback),
      );
    };

    const displayNotification = () => {
      NotificationManager.success(
        <FormattedMessage id="Product.Image.Updated" />,
      );
    };

    removeProductImage(() => this.closeModal(displayNotification));
  }

  // In mercurial "create" mode, Step 3 is when we let the ability to the user to upload associated categories/products images
  openUploadProductImgModal(productFormat, currentProductImage) {
    this.setState({
      modal: (
        <UploadProductImgModal
          closeModal={() => this.closeModal()}
          productFormat={productFormat}
          currentProductImage={currentProductImage}
          mode="upload"
          onComplete={(newProductImage) =>
            this.uploadImage(productFormat.ref_frn, newProductImage)
          }
        />
      ),
    });
  }

  // In mercurial "create" mode, Step 3 is when we let the ability to the user to upload associated categories/products images
  openDeleteProductImgModal(
    productFormat,
    currentProductImage,
    defaultProductImage,
  ) {
    this.setState({
      modal: (
        <UploadProductImgModal
          closeModal={() => this.closeModal()}
          productFormat={productFormat}
          currentProductImage={currentProductImage}
          defaultProductImage={defaultProductImage}
          mode="delete"
          onComplete={() => this.removeImage(productFormat.ref_frn)}
        />
      ),
    });
  }

  openConfModal(oldPrice, price, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          variant={"warning"}
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          <Alert variant="danger">
            <strong>
              <p>
                <FormattedMessage id="Warning" /> :{" "}
                <FormattedMessage
                  id="Unit.Price.Excl.Tax.Update.Confirm"
                  values={{ from: Maths.round(oldPrice, 4), to: price }}
                />
              </p>

              <p>
                <FormattedMessage id="Unit.Price.Excl.Tax.Update.Warning" />
              </p>
            </strong>
          </Alert>
          <p>
            <FormattedMessage id="Confirm.Choice" />
          </p>
        </ConfirmationModal>
      ),
    });
  }

  getModalTitle() {
    const isMainProduct =
      this.props.product && !this.props.product.parentProductRefFrn;

    if (this.props.isAddDeclination)
      return <FormattedMessage id="Add.Product.Declination" />;

    if (this.props.product && !isMainProduct)
      return <FormattedMessage id="Product.Declination.Update" />;

    if (this.props.product)
      return <FormattedMessage id="Product.Main.Update" />;

    return <FormattedMessage id="Add.Product" />;
  }

  onAddComboboxOption(option, successCallback) {
    const newOption = { _id: option.name, name: option.name };
    this.setState(
      {
        subCategories: [...this.state.subCategories, newOption],
        sous_famille: option.name,
      },
      successCallback(newOption),
    );
  }

  checkIfRefFrnExists(value) {
    this.props.onCheckIfRefFrnExists(
      { mercurialId: this.props.mercurialId, refFrn: value },
      () =>
        this.setState({
          refFrnError: <FormattedMessage id="Supplier.Ref.Already.Exists" />,
        }),
      () => this.setState({ refFrnError: "" }),
    );
  }

  getPriceHT(priceTTC, tva) {
    if (!tva || tva === 0) return priceTTC;
    return priceTTC / (1 + tva / 100);
  }

  getPriceTTC(priceHt, tva) {
    const product = {
      prix_u_ht_emera: priceHt,
      tva: this.formatTva(tva),
    };

    return PriceUtil.priceTtc(product, 1, 2);
  }

  calculateMarginRate(priceHt, purchasePriceHt, type = "margin") {
    if (!priceHt || priceHt === 0 || !purchasePriceHt || purchasePriceHt === 0)
      return 0;

    const margin = priceHt - purchasePriceHt;
    const divider = type === "margin" ? purchasePriceHt : priceHt;

    return (margin / divider) * 100;
  }

  calculatePriceHtFromMarginRate(purchasePrice, marginRate) {
    return (1 + marginRate / 100) * purchasePrice;
  }

  calculatePriceHtFromGrossMarginRate(purchasePrice, grossMarginRate) {
    return purchasePrice / (1 - grossMarginRate / 100);
  }

  checkMargin(margin, marginMin, marginMax, messageId) {
    if (margin < marginMin || margin > marginMax) {
      this.setState({
        margeError: (
          <FormattedMessage
            id={messageId}
            values={{
              min: marginMin,
              max: marginMax,
            }}
          />
        ),
      });
    } else {
      this.setState({ margeError: "" });
    }
  }

  checkMarginsRate(marginRate, grossMarginRate) {
    let marginRateToCheck = marginRate;
    let rateMin = this.props.productsSettingsAdmin.margin_rate_min;
    let rateMax = this.props.productsSettingsAdmin.margin_rate_max;
    let errorMsg = "Margin.Rate.Out.Of.Bounds";

    if (this.props.productsSettingsAdmin.margin_selected !== "margin") {
      marginRateToCheck = grossMarginRate;
      rateMin = this.props.productsSettingsAdmin.gross_margin_rate_min;
      rateMax = this.props.productsSettingsAdmin.gross_margin_rate_max;
      errorMsg = "Gross.Margin.Rate.Out.Of.Bounds";
    }

    this.checkMargin(marginRateToCheck, rateMin, rateMax, errorMsg);
  }

  checkPriceLimit() {
    if (
      this.state.prix_limite_ttc &&
      this.state.prix_limite_ttc < this.state.prix_ttc
    ) {
      this.setState({
        priceLimitError: <FormattedMessage id="Unit.Price.Incl.Tax.Error" />,
      });
    } else {
      this.setState({ priceLimitError: "" });
    }
  }

  onChangePurchasePrice(value) {
    if (!this.state.prix_u_ht_emera || this.state.prix_u_ht_emera === 0)
      return this.setState({ prix_achat_ht: value });

    let marginRate = this.state.taux_marge;
    let grossMarginRate = this.state.taux_marque;

    marginRate = Maths.round(
      this.calculateMarginRate(this.state.prix_u_ht_emera, value),
      2,
    );
    grossMarginRate = Maths.round(
      this.calculateMarginRate(
        this.state.prix_u_ht_emera,
        value,
        "gross_margin",
      ),
      2,
    );
    this.setState({
      prix_achat_ht: Maths.round(value, 2),
      taux_marge: marginRate,
      taux_marque: grossMarginRate,
      prix_ttc: this.getPriceTTC(this.state.prix_u_ht_emera, this.state.tva),
    });

    this.checkMarginsRate(marginRate, grossMarginRate);
    this.checkPriceLimit();
  }

  onChangeMarginRate(value) {
    if (!this.state.prix_achat_ht || this.state.prix_achat_ht === 0)
      return this.setState({ taux_marge: value });

    let marginRate = this.state.taux_marge;
    let grossMarginRate = this.state.taux_marque;

    const updatedPriceHt = this.calculatePriceHtFromMarginRate(
      this.state.prix_achat_ht,
      value,
    );
    marginRate = Maths.round(value, 2);
    grossMarginRate = Maths.round(
      this.calculateMarginRate(
        updatedPriceHt,
        this.state.prix_achat_ht,
        "gross_margin",
      ),
      2,
    );
    this.setState({
      taux_marge: marginRate,
      prix_u_ht_emera: Maths.round(updatedPriceHt, 2),
      taux_marque: grossMarginRate,
      prix_ttc: this.getPriceTTC(updatedPriceHt, this.state.tva),
    });

    this.checkMarginsRate(marginRate, grossMarginRate);
    this.checkPriceLimit();
  }

  onChangeGrossMarginRate(value) {
    if (!this.state.prix_achat_ht || this.state.prix_achat_ht === 0)
      return this.setState({ taux_marque: value });

    let marginRate = this.state.taux_marge;
    let grossMarginRate = this.state.taux_marque;

    const updatedPriceHt = this.calculatePriceHtFromGrossMarginRate(
      this.state.prix_achat_ht,
      value,
    );
    marginRate = Maths.round(
      this.calculateMarginRate(updatedPriceHt, this.state.prix_achat_ht),
      2,
    );
    grossMarginRate = Maths.round(value, 2);
    this.setState({
      taux_marque: grossMarginRate,
      prix_u_ht_emera: Maths.round(updatedPriceHt, 2),
      taux_marge: marginRate,
      prix_ttc: this.getPriceTTC(updatedPriceHt, this.state.tva),
    });

    this.checkMarginsRate(marginRate, grossMarginRate);
    this.checkPriceLimit();
  }

  onChangePriceHt(value) {
    if (!this.state.prix_achat_ht || this.state.prix_achat_ht === 0)
      return this.setState({
        prix_u_ht_emera: value,
        prix_ttc: this.getPriceTTC(value, this.state.tva),
      });

    let marginRate = this.state.taux_marge;
    let grossMarginRate = this.state.taux_marque;

    marginRate = Maths.round(
      this.calculateMarginRate(value, this.state.prix_achat_ht),
      2,
    );
    grossMarginRate = Maths.round(
      this.calculateMarginRate(value, this.state.prix_achat_ht, "gross_margin"),
      2,
    );
    this.setState({
      prix_u_ht_emera: Maths.round(value, 2),
      taux_marge: marginRate,
      taux_marque: grossMarginRate,
      prix_ttc: this.getPriceTTC(value, this.state.tva),
    });

    this.checkMarginsRate(marginRate, grossMarginRate);
    this.checkPriceLimit();
  }

  onChangePriceTTC(value) {
    if (!this.state.prix_achat_ht || this.state.prix_achat_ht === 0)
      return this.setState({
        prix_ttc: value,
        prix_u_ht_emera: this.getPriceHT(value, this.state.tva),
      });

    let marginRate = this.state.taux_marge;
    let grossMarginRate = this.state.taux_marque;

    const updatedPriceHt = this.getPriceHT(value, this.state.tva);

    marginRate = Maths.round(
      this.calculateMarginRate(updatedPriceHt, this.state.prix_achat_ht),
      2,
    );
    grossMarginRate = Maths.round(
      this.calculateMarginRate(
        updatedPriceHt,
        this.state.prix_achat_ht,
        "gross_margin",
      ),
      2,
    );

    this.setState({
      prix_ttc: Maths.round(value, 2),
      prix_u_ht_emera: Maths.round(updatedPriceHt, 2),
      taux_marge: marginRate,
      taux_marque: grossMarginRate,
    });

    this.checkMarginsRate(marginRate, grossMarginRate);
    this.checkPriceLimit();
  }

  getAlertMessage() {
    if (!this.props.product || this.props.isAddDeclination) return null;

    const alert = (message) => (
      <Alert variant="warning">
        <FormattedMessage id={message} />
      </Alert>
    );

    const isMainProduct =
      this.props.product && !this.props.product.parentProductRefFrn;

    if (!isMainProduct) return alert("Product.Declination.Update.Warning");
    if (
      isMainProduct &&
      this.props.product.formats &&
      this.props.product.formats.length > 1
    )
      return alert("Product.Main.Update.Warning");

    return null;
  }

  render() {
    const currentProductImgUrl =
      this.props.product &&
      `${APIUrl.getProductImg}${this.props.mercurialId}/${encodeURIComponent(this.props.product.ref_frn)}/${Math.random()}/${encodeURIComponent(this.props.product.ref)}/${encodeURIComponent(this.props.product.parentProductRefFrn)}/?token=${APIUrl.jwtToken}`;
    const databaseDefaultProductImgUrl =
      this.props.product &&
      `${APIUrl.getProductImg}${this.props.mercurialId}/${encodeURIComponent(this.props.product.ref_frn)}/${Math.random()}/undefined/undefined/true/?token=${APIUrl.jwtToken}`;

    const isMainProduct =
      this.props.product && !this.props.product.parentProductRefFrn;

    const helpMessage = this.props.product &&
      isMainProduct &&
      !this.props.isAddDeclination && (
        <FormattedMessage id={"Hint.Product.Main.Update"} />
      );

    const getAlert = (context, title, error) => (
      <Row className="mb-1">
        <Col>
          <Alert variant={context}>
            <p>
              <strong>
                <FormattedMessage id={title} />
              </strong>
            </p>
            {error}
          </Alert>
        </Col>
      </Row>
    );

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop="static"
        size="xl"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.getModalTitle()}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.getAlertMessage()}
          <Row>
            <Col>
              <CustomLabel
                label={<FormattedMessage id="Image" />}
                htmlFor="image"
                displayAsCol
              />
            </Col>
          </Row>
          <Row className="mb-5">
            {this.props.product && !this.props.isAddDeclination && (
              <Col>
                <Row>
                  <Col className="text-center">
                    {this.state.customImg ? (
                      <div className="d-inline-block position-relative">
                        <div className="position-absolute" style={{ right: 0 }}>
                          <MenuButton
                            icon="trash"
                            onClick={() =>
                              this.openDeleteProductImgModal(
                                this.props.product,
                                currentProductImgUrl,
                                databaseDefaultProductImgUrl,
                              )
                            }
                            variant="danger"
                            className="btn-sm"
                          />
                        </div>
                        <img
                          className="img-thumbnail border-danger p-2"
                          style={{
                            width: "200px",
                            height: "200px",
                          }}
                          src={currentProductImgUrl}
                          alt={"product-" + Math.random()}
                        />
                      </div>
                    ) : (
                      <div className="d-inline-block position-relative">
                        <div className="position-absolute" style={{ right: 0 }}>
                          <MenuButton
                            icon="upload"
                            onClick={() =>
                              this.openUploadProductImgModal(
                                this.props.product,
                                currentProductImgUrl,
                                databaseDefaultProductImgUrl,
                              )
                            }
                            variant="success"
                            className="btn-sm"
                          />
                        </div>
                        <img
                          className="img-thumbnail border-success p-2"
                          style={{
                            width: "200px",
                            height: "200px",
                          }}
                          src={currentProductImgUrl}
                          alt={"product-" + Math.random()}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            )}
            {(!this.props.product ||
              (this.props.product && this.props.isAddDeclination)) && (
              <Col>
                <FileDropZone
                  onDropFile={(file) => this.setState({ file: [file] })}
                  acceptedExtensions={["jpg", "png", "jpeg"]}
                  multiple={false}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Family" />}
                    htmlFor="family"
                    labelClassName="ps-0"
                    required
                  />
                </Col>
                <Col sm={8}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="family"
                    autoComplete="off"
                    maxLength={InputLength.NAME}
                    value={this.state.famille}
                    disabled
                    onChange={(e) =>
                      this.setState({ famille: e.target.value.toUpperCase() })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Sub.Family" />}
                    htmlFor="sous-famille"
                    labelClassName="ps-0"
                    help={helpMessage}
                    required
                  />
                </Col>
                <Col sm={8}>
                  <ComboBox
                    disabled={
                      (this.props.product && !isMainProduct) ||
                      this.props.isAddDeclination
                    }
                    onCreateOption={(option, successCallback) =>
                      this.onAddComboboxOption(option, successCallback)
                    }
                    onChange={(e) => {
                      this.setState({ sous_famille: e });
                    }}
                    defaultOption={this.state.sous_famille}
                    options={this.state.subCategories}
                    placeholder={<FormattedMessage id="Select" />}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Supplier" />}
                    htmlFor="supplier"
                    labelClassName="ps-0"
                    help={helpMessage}
                    required
                  />
                </Col>
                <Col sm={8}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="supplier"
                    autoComplete="off"
                    maxLength={InputLength.NAME}
                    value={this.state.fournisseur}
                    disabled={
                      (this.props.product && !isMainProduct) ||
                      this.props.isAddDeclination
                    }
                    onChange={(e) =>
                      this.setState({
                        fournisseur: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Supplier.Ref" />}
                    htmlFor="ref_frn"
                    labelClassName="ps-0"
                    required
                  />
                </Col>
                <Col sm={8}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="ref_frn"
                    autoComplete="off"
                    maxLength={InputLength.ID}
                    value={this.state.ref_frn}
                    disabled={
                      this.props.product && !this.props.isAddDeclination
                    }
                    onChange={(e) => this.setState({ ref_frn: e.target.value })}
                    onBlur={() => this.checkIfRefFrnExists(this.state.ref_frn)}
                  />
                  <div className="text-danger">
                    <small>{this.state.refFrnError}</small>
                  </div>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Intern.Ref" />}
                    htmlFor="ref"
                    labelClassName="ps-0"
                    required
                  />
                </Col>
                <Col sm={8}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="ref"
                    autoComplete="off"
                    maxLength={InputLength.ID}
                    value={this.state.ref}
                    disabled={
                      this.props.product &&
                      !isMainProduct &&
                      !this.props.isAddDeclination
                    }
                    onChange={(e) => this.setState({ ref: e.target.value })}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Designation" />}
                    htmlFor="designation"
                    labelClassName="ps-0"
                    help={helpMessage}
                    required
                  />
                </Col>
                <Col sm={8}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="designation"
                    autoComplete="off"
                    maxLength={InputLength.TEXT}
                    value={this.state.designation}
                    disabled={
                      (this.props.product && !isMainProduct) ||
                      this.props.isAddDeclination
                    }
                    onChange={(e) =>
                      this.setState({
                        designation: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={
                      <FormattedMessage
                        id={
                          (this.props.product && !isMainProduct) ||
                          this.props.isAddDeclination
                            ? "Declination"
                            : "Model"
                        }
                      />
                    }
                    htmlFor="model"
                    labelClassName="ps-0"
                    required
                  />
                </Col>
                <Col sm={8}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="model"
                    autoComplete="off"
                    maxLength={InputLength.NAME}
                    value={this.state.model}
                    onChange={(e) =>
                      this.setState({ model: e.target.value.toUpperCase() })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Vending.unit" />}
                    htmlFor="unite_de_vente"
                    labelClassName="ps-0"
                    required
                  />
                </Col>
                <Col sm={8}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="unite_de_vente"
                    autoComplete="off"
                    maxLength={InputLength.TEXT}
                    value={this.state.unite_de_vente}
                    onChange={(e) =>
                      this.setState({ unite_de_vente: e.target.value })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Description" />}
                    htmlFor="caracteristiques"
                    labelClassName="ps-0"
                    help={helpMessage}
                  />
                </Col>
                <Col sm={8}>
                  <TextareaCounter
                    rows={8}
                    countLimit={2500}
                    id="caracteristiques"
                    value={this.state.caracteristiques}
                    disabled={
                      (this.props.product && !isMainProduct) ||
                      this.props.isAddDeclination
                    }
                    onChange={(e) =>
                      this.setState({ caracteristiques: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={4} className="ps-0">
              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Min.To.Order" />}
                    htmlFor="min_cde"
                    labelClassName="p-0"
                    required
                  />
                </Col>
                <Col sm={5}>
                  <InputNumber
                    id="min_cde"
                    min={1}
                    defaultValue={this.state.min_cde}
                    onChange={(event) => this.setState({ min_cde: event })}
                    onBlur={() =>
                      this.setState({ min_cde: parseInt(this.state.min_cde) })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={
                      <>
                        <FormattedMessage id="Purchase.Price" /> (
                        <FormattedMessage id="Excl.Tax" />)
                      </>
                    }
                    htmlFor="purchase_price_ht"
                    labelClassName="p-0"
                  />
                </Col>
                <Col sm={5}>
                  <InputGroup
                    style={{ borderBottom: 0 }}
                    id="purchase_price_ht"
                  >
                    <InputNumber
                      id={"inputGroupPurchasePrice"}
                      min={0}
                      defaultValue={this.state.prix_achat_ht}
                      onChange={(value) => this.onChangePurchasePrice(value)}
                    />
                    <InputGroup.Text id="inputGroupPurchasePrice">
                      €
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Margin.Rate" />}
                    htmlFor="margin_rate"
                    labelClassName="p-0"
                  />
                </Col>
                <Col sm={5}>
                  <InputGroup style={{ borderBottom: 0 }} id="margin_rate">
                    <InputNumber
                      id={"inputGroupMarginRate"}
                      min={1}
                      defaultValue={this.state.taux_marge}
                      onChange={(value) => this.onChangeMarginRate(value)}
                    />
                    <InputGroup.Text id="inputGroupMarginRate">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Gross.Margin.Rate" />}
                    htmlFor="gross_margin_rate"
                    labelClassName="p-0"
                  />
                </Col>
                <Col sm={5}>
                  <InputGroup
                    style={{ borderBottom: 0 }}
                    id="gross_margin_rate"
                  >
                    <InputNumber
                      id={"inputGroupGrossMarginRate"}
                      min={1}
                      max={99}
                      defaultValue={this.state.taux_marque}
                      onChange={(value) => this.onChangeGrossMarginRate(value)}
                    />
                    <InputGroup.Text id="inputGroupGrossMarginRate">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={
                      <>
                        <FormattedMessage id="Unit.Selling.Price" /> (
                        <FormattedMessage id="Excl.Tax" />)
                      </>
                    }
                    htmlFor="prix_u_ht_emera"
                    labelClassName="p-0"
                    required
                  />
                </Col>
                <Col sm={5}>
                  <InputGroup style={{ borderBottom: 0 }} id="prix_u_ht_emera">
                    <InputNumber
                      id={"inputGroupPrice"}
                      min={0}
                      defaultValue={this.state.prix_u_ht_emera}
                      onChange={(value) => this.onChangePriceHt(value)}
                    />
                    <InputGroup.Text id="inputGroupPrice">€</InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Unit.Tax" />}
                    htmlFor="tva"
                    labelClassName="p-0"
                    required
                  />
                </Col>
                <Col sm={5}>
                  <InputGroup style={{ borderBottom: 0 }} id="tva">
                    <InputNumber
                      id={"inputGroupTva"}
                      min={0}
                      max={100}
                      defaultValue={this.state.tva}
                      disabled={
                        (this.props.product && !isMainProduct) ||
                        this.props.isAddDeclination
                      }
                      onChange={(e) =>
                        this.setState({
                          tva: e,
                          prix_ttc: this.getPriceTTC(
                            this.state.prix_u_ht_emera,
                            e,
                          ),
                        })
                      }
                      onBlur={() =>
                        this.setState({
                          tva:
                            this.state.tva < 1
                              ? this.state.tva * 100
                              : this.state.tva,
                        })
                      }
                    />
                    <InputGroup.Text id="inputGroupTva">%</InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={
                      <>
                        <FormattedMessage id="Unit.Selling.Limit.Price" /> (
                        <FormattedMessage id="Incl.Tax" />)
                      </>
                    }
                    htmlFor="prix_limite_ttc"
                    labelClassName="p-0"
                    help={<FormattedMessage id={"Hint.PLV"} />}
                  />
                </Col>
                <Col sm={5}>
                  <InputGroup style={{ borderBottom: 0 }} id="prix_limite_ttc">
                    <InputNumber
                      id={"inputGroupPriceLimiteTtc"}
                      min={0}
                      defaultValue={this.state.prix_limite_ttc}
                      onChange={(value) =>
                        this.setState({ prix_limite_ttc: value })
                      }
                      onBlur={() => this.checkPriceLimit()}
                    />
                    <InputGroup.Text id="inputGroupPriceLimiteTtc">
                      €
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={
                      <>
                        <FormattedMessage id="Unit.Selling.Price" /> (
                        <FormattedMessage id="Incl.Tax" />)
                      </>
                    }
                    htmlFor="prix_ttc"
                    labelClassName="p-0"
                  />
                </Col>
                <Col sm={5}>
                  <InputGroup style={{ borderBottom: 0 }} id="prix_ttc">
                    <InputNumber
                      id={"inputGroupPriceTtc"}
                      min={0}
                      defaultValue={this.state.prix_ttc}
                      onChange={(value) => this.onChangePriceTTC(value)}
                      onBlur={() => this.checkPriceLimit()}
                    />
                    <InputGroup.Text id="inputGroupPriceTtc">€</InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Lpp.Code" />}
                    htmlFor="lpp_code"
                    labelClassName="p-0"
                  />
                </Col>
                <Col sm={5}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="lpp_code"
                    autoComplete="off"
                    maxLength={InputLength.ID}
                    value={this.state.lpp_code}
                    onChange={(e) =>
                      this.setState({ lpp_code: e.target.value })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <CustomLabel
                    label={<FormattedMessage id="Lpp.Amount" />}
                    htmlFor="lpp_amount"
                    labelClassName="p-0"
                  />
                </Col>
                <Col sm={5}>
                  <InputGroup style={{ borderBottom: 0 }} id="lpp_amount">
                    <InputNumber
                      id={"inputGroupAmountLPP"}
                      min={0}
                      defaultValue={this.state.lpp_amount}
                      onChange={(e) => this.setState({ lpp_amount: e })}
                    />
                    <InputGroup.Text id="inputGroupAmountLPP">
                      €
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              {this.state.margeError &&
                getAlert("warning", "Warning", this.state.margeError)}
              {this.state.priceLimitError &&
                getAlert("danger", "Error", this.state.priceLimitError)}
            </Col>
          </Row>

          {this.state.modal}
        </Modal.Body>

        <Modal.Footer>
          <MenuButton
            variant="secondary"
            onClick={() => this.props.closeModal()}
          >
            <FormattedMessage id="Cancel" />
          </MenuButton>
          <MenuButton
            onClick={() => this.onSubmit()}
            disabled={this.disabled()}
          >
            <FormattedMessage id="Confirm" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productsSettingsAdmin: state.productsSettingsAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddProductAdmin: (data, successCallback) =>
      dispatch(addProductAdmin(data, successCallback)),
    onUpdateProductAdmin: (data, successCallback) =>
      dispatch(updateProductAdmin(data, successCallback)),
    onAddProductsImgs: (mercurialId, data, successCallback) =>
      dispatch(addProductsImgs(mercurialId, data, successCallback)),
    onRemoveProductsImgs: (mercurialId, data, successCallback) =>
      dispatch(removeProductsImgs(mercurialId, data, successCallback)),
    onCheckIfRefFrnExists: (data, existsCallback, noExistsCallback) =>
      dispatch(checkIfRefFrnExists(data, existsCallback, noExistsCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ProductModal));
