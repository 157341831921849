import Util from "./Util";

export default class ProductsParserUtil {
  static parseProducts(products, filter) {
    let newProducts = [];

    outer: for (let p1 of products) {
      // If filter is a string, we assume this is the name of the selected family
      if (Util.typeOf(filter) === "String") {
        if (p1.famille !== filter) {
          continue;
        }
      }

      // If filter is an array, we assume this is the list of favorite products for current user
      else {
        if (
          !filter.includes(p1.ref_frn) &&
          !filter.map(String).includes(String(p1._id))
        ) {
          continue;
        }
      }

      // When calling Util.shallowClone(), the product description seems altered !
      // Dunno why...
      // Exemple "LANCETTES GLUCO PRO 23G - 2,0 x 0,6mm" becomes "LANCETTES GLUCO PRO 23G -"
      let p = Util.shallowClone(p1);

      // Make sure p1 is not already in newProducts
      for (let n of newProducts) {
        if (ProductsParserUtil.equal(p, n)) continue outer;
      }

      let formats = {};
      let formatP = ProductsParserUtil.getFormat(p);
      formats[formatP] = {
        _id: p1._id,
        format: formatP,
        ref: p.ref,
        ref_frn: p.ref_frn,
        prix_u_ht_emera: p.prix_u_ht_emera,
        prix_achat_ht: p.prix_achat_ht,
        taux_marge: p.taux_marge,
        taux_marque: p.taux_marque,
        tva: p.tva,
        unite_de_vente: p.unite_de_vente,
        parentProductRefFrn: null,
        customImg: p.customImg,
        sous_famille: p1.sous_famille,
        fournisseur: p1.fournisseur,
        designation: p1.designation,
        caracteristiques: p1.caracteristiques,
        lpp_amount: p1.lpp_amount,
        lpp_code: p1.lpp_code,
        sortIndex: p1.sortIndex,
        preview_taux_marque: p1.preview_taux_marque,
        preview_taux_marge: p1.preview_taux_marge,
        preview_prix_ht: p1.preview_prix_ht,
        preview_prix_ttc: p1.preview_prix_ttc,
        mercurial_id: p1.mercurial_id,
      };

      // Get all formats for identical products
      for (let p2 of products) {
        if (ProductsParserUtil.equal(p, p2)) {
          let formatP2 = ProductsParserUtil.getFormat(p2);
          if (!formats[formatP2])
            formats[formatP2] = {
              _id: p2._id,
              format: formatP2,
              ref: p2.ref,
              ref_frn: p2.ref_frn,
              prix_u_ht_emera: p2.prix_u_ht_emera,
              prix_achat_ht: p2.prix_achat_ht,
              taux_marge: p2.taux_marge,
              taux_marque: p2.taux_marque,
              tva: p2.tva,
              unite_de_vente: p2.unite_de_vente,
              parentProductRefFrn: p1.ref_frn,
              customImg: p2.customImg,
              sous_famille: p2.sous_famille,
              fournisseur: p2.fournisseur,
              designation: p2.designation,
              caracteristiques: p2.caracteristiques,
              lpp_amount: p2.lpp_amount,
              lpp_code: p2.lpp_code,
              sortIndex: p2.sortIndex,
              preview_taux_marque: p2.preview_taux_marque,
              preview_taux_marge: p2.preview_taux_marge,
              preview_prix_ht: p2.preview_prix_ht,
              preview_prix_ttc: p2.preview_prix_ttc,
              mercurial_id: p2.mercurial_id,
            };
        }
      }

      // Insert product with reformatted designation and formats
      p.designation =
        formats && formats.length > 1
          ? p.designation.replace(formatP, "")
          : p1.designation;

      p.formats = Object.values(formats);
      newProducts.push(p);
    }

    return newProducts;
  }

  static equal(product1, product2) {
    let isolatedDesignation1 = product1.designation
      .replace(ProductsParserUtil.getFormat(product1), "")
      .trim();
    let isolatedDesignation2 = product2.designation
      .replace(ProductsParserUtil.getFormat(product2), "")
      .trim();

    return (
      product1.famille === product2.famille &&
      product1.sous_famille === product2.sous_famille &&
      product1.caracteristiques === product2.caracteristiques &&
      isolatedDesignation1 === isolatedDesignation2 &&
      product1.mercurial_id === product2.mercurial_id
    );
  }

  static getFormat(product) {
    if (!product.designation) return null;

    if (
      product.designation.indexOf(process.env.REACT_APP_PRODUCT_DELIMITER) === 0
    )
      return null;

    let substrings = product.designation.split(
      process.env.REACT_APP_PRODUCT_DELIMITER,
    );
    return substrings[substrings.length - 1];
  }

  static getDesignation(product) {
    if (!product.designation) return product.sous_famille;

    if (
      product.designation.indexOf(process.env.REACT_APP_PRODUCT_DELIMITER) === 0
    )
      return product.designation;

    return product.designation
      .split(process.env.REACT_APP_PRODUCT_DELIMITER)[0]
      .trim();
  }

  static swapDesignationDelimiter(designation) {
    if (designation.indexOf(process.env.REACT_APP_PRODUCT_DELIMITER) > 0) {
      var delimiter = new RegExp(process.env.REACT_APP_PRODUCT_DELIMITER, "g");
      designation = designation.replace(delimiter, "-");
    }

    return designation;
  }

  static trim(product) {
    if (!product.designation) return null;

    var result = product.designation;

    while (
      result[result.length - 1] === " " ||
      result[result.length - 1] === process.env.REACT_APP_PRODUCT_DELIMITER
    ) {
      result = result.substring(0, result.length - 1).trim();
    }

    return result;
  }

  static trimWithId(product) {
    if (!product.designation) return null;

    var result = product.designation;

    while (
      result[result.length - 1] === " " ||
      result[result.length - 1] === process.env.REACT_APP_PRODUCT_DELIMITER
    ) {
      result = result.substring(0, result.length - 1);
    }

    return result;
  }
}
